<template>
  <div>
    <vs-popup class="popupwidth" :title="`${this.popup_title}`" :active.sync="dues_raw_popup">
        <br/>
        <vs-table v-if="dues_raw_popup" max-items="10" pagination :data="popup_due_status">
          <template slot="thead">
            <!-- <vs-th>
              Person ID
            </vs-th> -->
            <vs-th>
              Can-ID
            </vs-th>
            <vs-th>
              Person Name
            </vs-th>
            <vs-th>
              Enrollment Date
            </vs-th>
            <vs-th>
              Course
            </vs-th>
            <vs-th>
              Batch
            </vs-th>
            <vs-th>
              Loan
            </vs-th>
            <vs-th>
              Defaulter
            </vs-th>
            <vs-th>
              City
            </vs-th>
            <vs-th>
              Spoc name
            </vs-th>
            <vs-th>
              Reporting Manager
            </vs-th>
            <vs-th>
              Team
            </vs-th>
            <vs-th>
              Agreed cost
            </vs-th>
            <vs-th>
              Amount paid
            </vs-th>
            <vs-th>
              Amount pending
            </vs-th>
            <vs-th>
              Comments
            </vs-th>
            <vs-th>
              Comment added by
            </vs-th>
            <vs-th>Loan ID</vs-th>
            <vs-th>
              Loan Status
            </vs-th>
            <vs-th>
              Actions
            </vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
              <!-- <vs-td :data="data[indextr].person_id">
                {{data[indextr].person_id}}
              </vs-td> -->
              <vs-td :data="data[indextr].identity">
                {{data[indextr].identity}}
              </vs-td>
              <vs-td :data="data[indextr].person_name">
                {{data[indextr].person_name}}
              </vs-td>
              <vs-td :data="data[indextr].enrollment_date">
                {{customFormatter(data[indextr].enrollment_date)}}
              </vs-td>
              <vs-td :data="data[indextr].course">
                {{data[indextr].course}}
              </vs-td>
              <vs-td :data="data[indextr].batch">
                {{data[indextr].batch}}
              </vs-td>
              <vs-td :data="data[indextr].loan">
                {{data[indextr].loan}}
              </vs-td>
              <vs-td :data="data[indextr].defaulter">
                {{data[indextr].defaulter}}
              </vs-td>
              <vs-td :data="data[indextr].city">
                {{data[indextr].city}}
              </vs-td>
              <vs-td :data="data[indextr].user_name">
                {{data[indextr].user_name}}
              </vs-td>
              <vs-td :data="data[indextr].reporting_manager">
                {{data[indextr].reporting_manager}}
              </vs-td>
              <vs-td :data="data[indextr].team">
                {{data[indextr].team}}
              </vs-td>
              <vs-td :data="data[indextr].agreed_cost">
                {{numberWithCommas(data[indextr].agreed_cost)}}
              </vs-td>
              <vs-td :data="data[indextr].amount_paid">
                {{numberWithCommas(data[indextr].amount_paid)}}
              </vs-td>
              <vs-td :data="data[indextr].amount_pending">
                {{numberWithCommas(data[indextr].amount_pending)}}
              </vs-td>
              <vs-td :data="data[indextr].payment_comment">
                {{ data[indextr].payment_comment }}
              </vs-td>
              <vs-td :data="data[indextr].payment_comments_added_by_name">
                {{ data[indextr].payment_comments_added_by_name }}
              </vs-td>
              <vs-td :data="data[indextr].loan_id">
                {{ data[indextr].loan_id }}
              </vs-td>
              <vs-td :data="data[indextr].loan_status">
                {{data[indextr].loan_status}}
              </vs-td>
              <vs-td>
                <vs-row>
                  <div data-tooltip="Info Popup" @click="openPopupEmit(data[indextr])">
                    <vs-icon
                      icon="info_outline"
                      size="small"
                      color="dark"
                    ></vs-icon>
                  </div>
                  <!-- <div data-tooltip="Mark as Defaulter" v-if="status" @click="markAsDefaulter(data[indextr])">
                    <vs-icon
                      icon="swap_horizontal_circle"
                      size="small"
                      color="dark"
                    ></vs-icon>
                  </div> -->
                  <div data-tooltip="Fetch Receipts for Purchase" @click="fetchReceiptsForPurchase(data[indextr])">
                    <img src="https://img.icons8.com/ios-filled/18/000000/r.png" />
                  </div>

                  <div data-tooltip="Mark as Loan" @click="markAsLoan(data[indextr])" v-if="data[indextr].loan === 0">
                    <img src="https://img.icons8.com/ios-filled/18/000000/l.png" />
                  </div>

                  <div data-tooltip="Update Loan" @click="updateLoan(data[indextr])" v-if="data[indextr].loan === 1">
                    <img src="https://img.icons8.com/ios-filled/18/000000/l.png" />
                  </div>

                  <div data-tooltip="Payment Settings" @click="openPaymentSettings(data[indextr])">
                    <img
                      src="https://img.icons8.com/ios-filled/18/000000/settings.png"
                    />
                  </div>

                  <div data-tooltip="Remove from loan"  @click="enableRemoveFromLoanPopup(data[indextr])" v-if="data[indextr].loan === 1">
                    <img
                      src="https://img.icons8.com/ios-glyphs/18/000000/multiply.png"
                    />
                  </div>
                </vs-row>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table> 
    </vs-popup>
    <!-- Mark as Defaulter and Mark as Loan Popup -->
    <vs-popup class="holamundo" title="Are you sure?" :active.sync="popupOpen">
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <b>{{ text }}</b>
        </vs-col>
      </vs-row>
      <vs-row
        style="margin-top: 2%"
        v-if="text === 'Are you sure you want to mark this lead as a loan?'"
      >
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-input label-placeholder="Loan ID" v-model="loan_id" />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
        >
          <v-select
            style="position: fixed; width: 200px; z-index: 1000; height: 20px "
            :options="loan_status_values"
            v-model="loan_status"
          />
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-button color="danger" type="border" @click="popupOpen = false"
            >Cancel</vs-button
          >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
        >
          <vs-button
            color="success"
            type="border"
            @click="defualterRequest(defaulterPayload)"
            >Yes</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
    <!-- Payment Settings Popup-->
    <vs-popup
      class="holamundo"
      title="Payment Settings"
      :active.sync="settingsOpen"
    >
      <vs-row style="margin-top: 2%; margin-bottom:2%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <b>Can Id </b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
          <b>:</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="8"
        >
          {{ can_details.identity }}
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%; margin-bottom:2%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <b>Name </b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
          <b>:</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="8"
        >
          {{ can_details.person_name }}
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%; margin-bottom:2%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <b>Course </b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
          <b>:</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="8"
        >
          {{ can_details.course }}
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 3%; margin-bottom:2%">
        
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <b>Expected Date </b>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="1">
          <b>:</b>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="8"
        >
          <datepicker
            placeholder="Select Date"
            style="z-index: 1000; position: fixed"
            :format="customFormatter"
            v-model="expected_payment_date"
          ></datepicker>
        </vs-col>
      </vs-row>
      
      <vs-row style="margin-top: 4%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="12"
        >
          <vs-textarea label="Comments" v-model="expected_comments" />
        </vs-col>
      </vs-row>

      <vs-collapse>
        <vs-collapse-item>
          <div slot="header">
            History
          </div>
          <div class="mb-4">
            <vs-row>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="flex-start"
                vs-w="3"
              >
                <b>Date</b>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
              >
              <b>Comment</b>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="3"
              >
              <b>Added by</b>
              </vs-col>
            </vs-row>
          </div>
          <div v-for="(comment, index) in commentsHistory" :key="index">
            <vs-row>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="flex-start"
                vs-w="3"
              >
                <b>{{ getDate(comment.created_at) }}</b>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
              >
                {{ comment.comments }}
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="3"
              >
                {{ comment.added_by_name }}
              </vs-col>
            </vs-row>
            <vs-divider />
          </div>
        </vs-collapse-item>
      </vs-collapse>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-button color="danger" type="border" @click="settingsOpen = false"
            >Cancel</vs-button
          >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
        >
          <vs-button color="success" type="border" @click="saveSettings()"
            >Save</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
    <!--Update Loan -->
    <vs-popup
      class="holamundo"
      title="Are you sure?"
      :active.sync="updateLoadStatus"
    >
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <b>Update Loan Status</b>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-input label-placeholder="Loan ID" v-model="loan_id" />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
        >
          <v-select
            style="position: fixed; width: 200px; z-index: 1000; height: 20px "
            :options="loan_status_values"
            v-model="loan_status"
          />
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-button color="danger" type="border" @click="updateLoadStatus = false"
            >Cancel</vs-button
          >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
        >
          <vs-button
            color="success"
            type="border"
            @click="UpdateLoadForCandidate(defaulterPayload)"
            >Save</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
    <!-- Remove from Loan Popup -->
    <vs-popup
      class="holamundo"
      title="Are you sure?"
      :active.sync="removeFromLoanPopup"
    >
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <b> Are you sure you want to remove this cadidate from loan?</b>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="6">
          <vs-button color="danger" type="border" @click="removeFromLoanPopup = false"
            >Cancel</vs-button
          >
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
        >
          <vs-button
            color="success"
            type="border"
            @click="removeFromLoan(defaulterPayload)"
            >Yes</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
    <!-- purchase receipts popup -->
    <receiptspopup></receiptspopup>
  </div>
</template>

<script>
import receiptspopup from "../pagesComponents/ReceiptsPopup.vue";
import constants from "../../../constants.json";
import Datepicker from "vuejs-datepicker";
import eventbus from "../eventbus";
import vSelect from "vue-select";
import moment from "moment";
import axios from "axios";
export default {
  components:{
    Datepicker,
    receiptspopup,
    "v-select": vSelect,
  },
  data(){
    return {
      expected_comments: "",
      commentsHistory: [],
      dues_raw_popup: false,
      popup_title : "", 
      popup_due_status: "",
      text: "",
      url: "",
      status: false,
      defaulterPayload: {},
      popupOpen: false,
      loan_status_values: [
          "Not created",
          "Created",
          "In Review",
          "Documentation",
          "Push for disbursal",
          "Disbursed",
          "Rejected",
          "Dropped",
        ],
        loan_status: "Created",
        loan_id: "",
        updateLoadStatus: false,
        settingsOpen: false,
        expected_payment_date: null,
        removeFromLoanPopup: false,
        can_details: '',
    };
  },
  mounted(){
    eventbus.$on("duesummary-sumrawcount-popup", (popup_due_status, popup_title, due_status) => {
      console.log("duesummary-sumrawcount-popup",popup_due_status, popup_title, due_status);
      this.popup_due_status = popup_due_status;
      this.popup_title = popup_title;
      this.status = true;
      if(due_status === 'defaulter'){
        this.status = false;
      }
      if (!this.getrole("cm4")) {
        this.status = false;
      }
      this.dues_raw_popup = true;
    });
  },
  methods:{
    getDate(created_at) {
      return moment(created_at).format("DD-MM-YYYY");
    },
    customFormatter(enrollment_date) {
      return moment(enrollment_date).format("DD-MMM-YYYY");
    },
    numberWithCommas(x) {
      return x.toLocaleString('en-IN');
    },
    openPopupEmit(payload) {
      console.log("payload sum raw",payload);
      this.openBigPopup(payload.mwb_id);
    },
    fetchReceiptsForPurchase(purchase) {
        console.log("fetchReceiptsForPurchase",purchase);
        this.startloading();
        let url = `${constants.SERVER_API}fetchReceiptsForPurchase`;
        let obj = {
          purchase_id: purchase.purchases_id,
        };
        console.log(obj);
        axios
          .get(url, {
            params: obj,
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          })
          .then((response) => {
            console.log(response);
            if (response.data.status === "success") {
              this.stoploading();
              eventbus.$emit("open-receipts-popup", response.data.receipts);
            } else {
              this.handleNotification(response);
            }
          })
          .catch((error) => {
            this.stoploading();
            this.handleError(error);
          });
    },
    openPaymentSettings(payload) {
      console.log("openPaymentSettings",payload);
      this.can_details = payload;
      this.commentsHistory = [];
      this.expected_payment_date = null;
      this.expected_comments = "";
      this.startloading();
      let url = `${constants.SERVER_API}getPaymentCommentHistory`;
      let obj = {
        purchase_id: payload.purchases_id,
      };
      console.log("openPaymentSettings",obj);
      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.stoploading();
          if (response.data.status === "success") {
            this.commentsHistory = response.data.data;
            this.defaulterPayload = payload;
            this.settingsOpen = true;
          } else {
            this.handleNotification(response);
          }
        })
        .catch((error) => {
          this.stoploading();
          this.handleError(error);
        });
    },
    saveSettings() {
      console.log("expected_payment_date",this.expected_payment_date);
      let obj = {
        expected_date:
          this.expected_payment_date === null
            ? null
            : this.datatoTimestamp(this.expected_payment_date),
        comments: this.expected_comments,
        purchase_id: this.defaulterPayload.purchases_id,
      };
      console.log("saveSettings",obj);
      let url = `${constants.SERVER_API}updatePaymentInfoForPurchase`;
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.settingsOpen = false;
            this.popup_due_status.forEach((purchase) => {
              if (purchase.purchases_id === this.defaulterPayload.purchases_id) {
                purchase.payment_comment = obj.comments;
                purchase.payment_expected_date = obj.expected_date;
              }
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    updateLoan(payload) {
      console.log("update loan",payload);
      this.defaulterPayload = payload;
      this.loan_status = payload.loan_status;
      this.loan_id = payload.loan_id;
      this.updateLoadStatus = true;
    },
    UpdateLoadForCandidate() {
      let obj = {
        purchase_id: this.defaulterPayload.purchases_id,
        loan_id: this.loan_id,
        loan_status: this.loan_status,
      };
      console.log("UpdateLoadForCandidate",obj);
      let url = `${constants.SERVER_API}updateLoanStatus`;
      console.log(url);
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.updateLoadStatus = false;
            this.popup_due_status.forEach((purchase) => {
              if (purchase.purchases_id === this.defaulterPayload.purchases_id) {
                purchase.loan_id = this.loan_id;
                purchase.loan_status = this.loan_status;
              }
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    markAsDefaulter(payload) {
      console.log("markAsDefaulter",payload);
      this.text = "Are you sure you want to mark this lead as a defaulter?";
      this.url = "markAsDefaulter";
      this.defaulterPayload = payload;
      this.popupOpen = true;
    },
    markAsLoan(payload) {
      this.text = "Are you sure you want to mark this lead as a loan?";
      this.url = "markAsLoan";
      this.loan_id = "";
      this.loan_status = "";
      this.defaulterPayload = payload;
      this.popupOpen = true;
    },
    defualterRequest(payload) {
      console.log("payload",payload);
      let url = `${constants.SERVER_API}${this.url}`;
      let obj = {};
      if (this.url === "markAsDefaulter") {
        obj = {
          purchase_id: payload.purchases_id,
          mwb_id: payload.mwb_id,
          person_id: payload.person_id,
          identity: payload.identity,
          enrollment_id: payload.enrollment_id,
          user_id: localStorage.getItem("spoc_id"),
          action: 'defaulter_added',
        };
      } else if (this.url === "markAsLoan") {
        obj = {
          purchase_id: payload.purchases_id,
          loan_id: this.loan_id,
          loan_status: this.loan_status,
        };
      }
      console.log("defualterRequest",obj, url);
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.handleNotification(response);
          if (response.data.status === "success") {
            this.dues_raw_popup = false;
            this.popupOpen = false;
            eventbus.$emit("reload-dues-count-sum-data");
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    enableRemoveFromLoanPopup(payload) {
      this.defaulterPayload = payload;
      this.removeFromLoanPopup = true;
    },
    removeFromLoan(payload) {
      console.log("removeFromLoan",payload);
      this.startloading();
      let url = `${constants.SERVER_API}removeFromloan`;
      let obj = {
        purchase_id: payload.purchases_id,
      };
      console.log("removeFromLoan",obj, url);
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.stoploading();
          this.handleNotification(response);
          this.dues_raw_popup = false;
          this.removeFromLoanPopup = false;
          eventbus.$emit("reload-dues-count-sum-data");
        })
        .catch((error) => {
          this.stoploading();
          this.handleError(error);
        });
    },
  }
}
</script>

<style>

</style>