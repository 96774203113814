<template>
  <div>
    <vs-card actionable class="cardx">
      <div style="padding-left: 1%">
        <h4>Due Summary</h4>
      </div>
      <br/>
      <vs-row style="padding-bottom: 1%">
        <vs-col 
          vs-type="flex" 
          vs-align="center"
          vs-justify="flex-start" 
          vs-w="1"
        >
         <p style="font-size: 15px"><b>Team :</b></p>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
        <treeselect :disabled="disabled" placeholder="Team" v-model="init_selected_team" :multiple="true" :options="treeTeam" :value-consists-of="'LEAF_PRIORITY'"/>
        </vs-col>
        <vs-col 
          vs-type="flex" 
          vs-align="center"
          vs-justify="flex-start" 
          vs-w="1"
        >
         <p style="font-size: 15px"><b>City :</b></p>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
        <treeselect :limit="3" placeholder="City" v-model="initSelectedCity" :multiple="true" :options="treeDataCity" :value-consists-of="'LEAF_PRIORITY'" valueFormat="object"/>
        </vs-col>
        <vs-col 
          vs-type="flex" 
          vs-align="center"
          vs-justify="flex-start" 
          vs-w="1"
        >
         <p style="font-size: 15px"><b>GM :</b></p>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
        <treeselect placeholder="GM" :limit="3" v-model="initSelectedGM" :multiple="true" :options="treeDataGM" :value-consists-of="'LEAF_PRIORITY'" valueFormat="object"/>
        </vs-col>
        <vs-col 
          vs-type="flex" 
          vs-align="center"
          vs-justify="flex-start" 
          vs-w="1"
        >
         <p style="font-size: 15px"><b>SPOC :</b></p>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
        <treeselect placeholder="SPOC" :limit="3" v-model="initSelectedSpoc" :multiple="true" :options="treeDataSpoc" :value-consists-of="'LEAF_PRIORITY'" valueFormat="object"/>
        </vs-col>

      </vs-row>
      <vs-row style="padding-bottom: 1%">
        <!-- <vs-col 
          vs-type="flex" 
          vs-align="center"
          vs-justify="flex-start" 
          vs-w="1"
        >
         <p style="font-size: 15px"><b>Enrollment City :</b></p>
        </vs-col> -->
        <!-- <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <v-select
            placeholder="City"
            class="w-full"
            v-model="selected_city"
            :options="cities"
          >
          </v-select>
        </vs-col> -->
        <vs-col 
          vs-type="flex" 
          vs-align="center" 
          vs-justify="flex-start" 
          vs-w="1"
        >
          <p style="font-size: 15px"><b>Course :</b></p>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <v-select
            placeholder="Course"
            class="w-full"
            v-model="selected_course"
            :options="courses"
          >
          </v-select>
        </vs-col>
        
        <vs-col 
          vs-type="flex" 
          vs-align="center" 
          vs-justify="flex-start" 
          vs-w="1"
        >
          <p style="font-size: 15px"><b>Year :</b></p>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <v-select
            placeholder="Select Year"
            class="w-full"
            v-model="selected_year"
            :options="years_list"
          >
          </v-select>
        </vs-col>
        <vs-col 
          vs-type="flex" 
          vs-align="center" 
          vs-justify="flex-start" 
          vs-w="1"
        >
          <p style="font-size: 15px"><b>Loan :</b></p>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <v-select
            placeholder="Loan Status"
            class="w-full"
            v-model="selected_loan_status_object"
            :options="loan_status"
            label="text"
          ></v-select>
        </vs-col>
        <vs-col 
          vs-type="flex" 
          vs-align="center" 
          vs-justify="flex-start" 
          vs-w="1"
        >
          <p style="font-size: 15px"><b>Due Status :</b></p>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <v-select
            placeholder="Due Status"
            class="w-full"
            v-model="selected_due_type_object"
            :options="due_types"
            label="text"
          >
          </v-select>
        </vs-col>
      </vs-row>
      <vs-row>
        <!-- <vs-col 
          vs-type="flex" 
          vs-align="center" 
          vs-justify="flex-start" 
          vs-w="1"
        >
          <p style="font-size: 15px"><b>GM :</b></p>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <v-select
            placeholder="GM Spoc"
            class="w-full"
            v-model="selected_gm_spoc"
            :options="gm_spocs"
          >
          </v-select>
        </vs-col> -->
        
        
      </vs-row>
      <br/>
      <vs-row>
        <vs-col 
          vs-type="flex" 
          vs-align="center" 
          vs-justify="flex-start" 
          vs-w="1"
        >
          <p style="font-size: 15px"><b>Date Range:</b></p>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
        >
          <div id="datepicker" style="padding-top: 2%">
            <date-range-picker
              ref="picker"
              :opens="'right'"
              :locale-data="{ firstDay: 1, format: 'DD-MMM-YYYY' }"
              :minDate="null"
              :maxDate="null"
              :singleDatePicker="false"
              :timePicker="false"
              :timePicker24Hour="true"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="true"
              v-model="date_range"
              style="width: 140%"
            ></date-range-picker>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="9"
          >
          <vs-button 
            radius
            icon="search"
            type="gradient"
            color="dark"
            @click="getData()"
          > 
          </vs-button>
        </vs-col>
        <!-- <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="1"
          >
          <vs-button 
            radius
            icon="refresh"
            type="gradient"
            color="dark"
            @click="refresh()"
          > 
          </vs-button>
        </vs-col> -->
      </vs-row>
      <br/>
      <vs-divider></vs-divider>
      <div style="padding-left: 1%">
        <h4>Due Summary Count and Sum</h4>
      </div>
      <br/>
      <vs-table :data="due_summary_count">
        <template slot-scope="{data}">
          <vs-tr>
            <vs-td>
              <b>Course</b>
            </vs-td>
            <vs-td>
              <b>Year</b>
            </vs-td>
            <vs-td style="text-align: right;">
             <b>Due</b>
            </vs-td>
            <vs-td style="text-align: right;">
              <b>Due Sum</b>
            </vs-td>
            <vs-td style="text-align: right;">
              <b>Overdue</b>
            </vs-td>
            <vs-td style="text-align: right;">
              <b>Overdue Sum</b>
            </vs-td>
            <vs-td style="text-align: right;">
              <b>Defaulter</b>
            </vs-td>
            <vs-td style="text-align: right;">
              <b>Defaulter Sum</b>
            </vs-td>
            <vs-td style="text-align: right;">
              <b>Incorrect Fee</b>
            </vs-td>
            <vs-td style="text-align: right;">
              <b>Incorrect Fee Sum</b>
            </vs-td>
            <vs-td style="text-align: right;">
              <b>Total Pending</b>
            </vs-td>
            <vs-td style="text-align: right;">
              <b>Total Pending Sum</b>
            </vs-td>
          </vs-tr>
          <template v-for="(tr, indextr) in data">
            <vs-tr :key="indextr"  v-if="data[indextr].sum_object != undefined">
              <vs-td :data="data[indextr].course">
                {{data[indextr].course}}
              </vs-td>
              <vs-td :data="data[indextr].year">
                {{data[indextr].year}}
              </vs-td>
              <vs-td style="text-align: right;" :data="data[indextr].due">
                <a
                  @click="displayPopupData('due',data[indextr].due, data[indextr].year, data[indextr].course, 'Due')"
                >
                  {{data[indextr].due}}
                </a>
              </vs-td>
              <vs-td style="text-align: right;" :data="data[indextr].due">
                <a
                  @click="displayPopupData('due',data[indextr].sum_object.due, data[indextr].year, data[indextr].course, 'Due')"
                >
                  {{numberWithCommas(data[indextr].sum_object.due)}}
                </a>
              </vs-td>
              <vs-td style="text-align: right;" :data="data[indextr].overdue">
                <a
                  @click="displayPopupData('over_due', data[indextr].overdue, data[indextr].year, data[indextr].course, 'Over Due')"
                >
                  {{data[indextr].overdue}}
                </a>
              </vs-td>
              <vs-td style="text-align: right;" :data="data[indextr].sum_object.overdue">
                <a
                  @click="displayPopupData('over_due', data[indextr].sum_object.overdue, data[indextr].year, data[indextr].course, 'Over Due')"
                >
                  {{numberWithCommas(data[indextr].sum_object.overdue)}}
                </a>
              </vs-td>
              <vs-td style="text-align: right;" :data="data[indextr].defaulter">
                <a
                  @click="displayPopupData('defaulter', data[indextr].defaulter, data[indextr].year, data[indextr].course, 'Defaulter')"
                >
                  {{data[indextr].defaulter}}
                </a>
              </vs-td>
              <vs-td style="text-align: right;" :data="data[indextr].sum_object.defaulter">
                <a
                  @click="displayPopupData('defaulter', data[indextr].sum_object.defaulter, data[indextr].year, data[indextr].course, 'Defaulter')"
                >
                  {{numberWithCommas(data[indextr].sum_object.defaulter)}}
                </a>
              </vs-td>
              <vs-td style="text-align: right;" :data="data[indextr].incorrect_fee">
                <a
                  @click="displayPopupData('incorrect_fee', data[indextr].incorrect_fee, data[indextr].year, data[indextr].course, 'Incorrect Fee')"
                >
                  {{data[indextr].incorrect_fee}}
                </a>
              </vs-td>
              <vs-td style="text-align: right;" :data="data[indextr].sum_object.incorrect_fee">
                <a
                  @click="displayPopupData('incorrect_fee', data[indextr].sum_object.incorrect_fee, data[indextr].year, data[indextr].course, 'Incorrect Fee')"
                >
                  {{numberWithCommas(data[indextr].sum_object.incorrect_fee)}}
                </a>
              </vs-td>
              <vs-td style="text-align: right;" :data="data[indextr].total_pending">
                <a
                  @click="displayPopupData('total_pending', data[indextr].total_pending, data[indextr].year, data[indextr].course, 'Total Pending')"
                >
                  {{data[indextr].total_pending}}
                </a>
              </vs-td>
              <vs-td style="text-align: right;" :data="data[indextr].sum_object.total_pending">
                <a
                  @click="displayPopupData('total_pending', data[indextr].sum_object.total_pending, data[indextr].year, data[indextr].course, 'Total Pending')"
                >
                  {{numberWithCommas(data[indextr].sum_object.total_pending)}}
                </a>
              </vs-td>
            </vs-tr>
          </template>
        </template>
      </vs-table>
      <br/>
      <!-- <div style="padding-left: 1%">
        <h4>Due Summary Sum</h4>
      </div>
      <br/>
      <vs-table :data="due_summary_sum">
        <template slot="thead">
          <vs-th>
            Course
          </vs-th>
          <vs-th>
            Year
          </vs-th>
          <vs-th>
            Due
          </vs-th>
          <vs-th>
            Overdue
          </vs-th>
          <vs-th>
            Defaulter
          </vs-th>
          <vs-th>
            Incorrect Fee
          </vs-th>
          <vs-th>
            Total pending
          </vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
            <vs-td :data="data[indextr].course">
              {{data[indextr].course}}
            </vs-td>
            <vs-td :data="data[indextr].year">
              {{data[indextr].year}}
            </vs-td>
            <vs-td :data="data[indextr].due">
              <a @click="displayPopupData('due', data[indextr].due, data[indextr].year, data[indextr].course, 'Due')">
                {{numberWithCommas(data[indextr].due)}}
              </a>
            </vs-td>
            <vs-td :data="data[indextr].overdue">
              <a
                @click="displayPopupData('over_due', data[indextr].overdue, data[indextr].year, data[indextr].course, 'Over Due')"
              >
                {{numberWithCommas(data[indextr].overdue)}}
              </a>
            </vs-td>
            <vs-td :data="data[indextr].defaulter">
              <a
                @click="displayPopupData('defaulter', data[indextr].defaulter, data[indextr].year, data[indextr].course, 'Defaulter')"
              >
                {{numberWithCommas(data[indextr].defaulter)}}
              </a>
            </vs-td>
            <vs-td :data="data[indextr].incorrect_fee">
              <a
                @click="displayPopupData('incorrect_fee', data[indextr].incorrect_fee, data[indextr].year, data[indextr].course, 'Incorrect Fee')"
              >
                {{numberWithCommas(data[indextr].incorrect_fee)}}
              </a>
            </vs-td>
            <vs-td :data="data[indextr].total_pending">
              <a
                @click="displayPopupData('total_pending', data[indextr].total_pending, data[indextr].year, data[indextr].course, 'Total Pending')"
              >
                {{numberWithCommas(data[indextr].total_pending)}}
              </a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>-->
      <due-summary-sum-count-raw-popup></due-summary-sum-count-raw-popup>
      <!-- <vs-popup class="popupwidth" :title="`${this.popup_title}`" :active.sync="dues_raw_popup">
        <br/>
        <vs-table max-items="10" pagination :data="popup_due_status">
          <template slot="thead">
           
            <vs-th>
              Identity
            </vs-th>
            <vs-th>
              Person Name
            </vs-th>
            <vs-th>
              Enrollment Date
            </vs-th>
            <vs-th>
              Course
            </vs-th>
            <vs-th>
              Batch
            </vs-th>
            <vs-th>
              Loan
            </vs-th>
            <vs-th>
              Defaulter
            </vs-th>
            <vs-th>
              City
            </vs-th>
            <vs-th>
              Spoc name
            </vs-th>
            <vs-th>
              Reporting Manager
            </vs-th>
            <vs-th>
              Team
            </vs-th>
            <vs-th>
              Agreed cost
            </vs-th>
            <vs-th>
              Amount paid
            </vs-th>
            <vs-th>
              Amount pending
            </vs-th>
            <vs-th>
              Status
            </vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
             
              <vs-td :data="data[indextr].identity">
                {{data[indextr].identity}}
              </vs-td>
              <vs-td :data="data[indextr].person_name">
                {{data[indextr].person_name}}
              </vs-td>
              <vs-td :data="data[indextr].enrollment_date">
                {{customFormatter(data[indextr].enrollment_date)}}
              </vs-td>
              <vs-td :data="data[indextr].course">
                {{data[indextr].course}}
              </vs-td>
              <vs-td :data="data[indextr].batch">
                {{data[indextr].batch}}
              </vs-td>
              <vs-td :data="data[indextr].loan">
                {{data[indextr].loan}}
              </vs-td>
              <vs-td :data="data[indextr].defaulter">
                {{data[indextr].defaulter}}
              </vs-td>
              <vs-td :data="data[indextr].city">
                {{data[indextr].city}}
              </vs-td>
              <vs-td :data="data[indextr].user_name">
                {{data[indextr].user_name}}
              </vs-td>
              <vs-td :data="data[indextr].reporting_manager">
                {{data[indextr].reporting_manager}}
              </vs-td>
              <vs-td :data="data[indextr].team">
                {{data[indextr].team}}
              </vs-td>
              <vs-td :data="data[indextr].agreed_cost">
                {{numberWithCommas(data[indextr].agreed_cost)}}
              </vs-td>
              <vs-td :data="data[indextr].amount_paid">
                {{numberWithCommas(data[indextr].amount_paid)}}
              </vs-td>
              <vs-td :data="data[indextr].amount_pending">
                {{numberWithCommas(data[indextr].amount_pending)}}
              </vs-td>
              <vs-td :data="data[indextr].status">
                {{data[indextr].status}}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table> 
      </vs-popup> -->
    </vs-card>
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import vSelect from "vue-select";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import axios from "axios";
import constants from "../../constants.json";
import moment from "moment";
import eventbus from "../components/eventbus";
import DueSummarySumCountRawPopup from "../components/pagesComponents/DueSummarySumCountRawPopup.vue";
export default {
  components: {
    DateRangePicker,
    "v-select": vSelect,
    DueSummarySumCountRawPopup,
    Treeselect,
  },
  data() {
    return {
      selected_year: '',
      years_list: [],
      selected_city: [],
      selected_gm: [],
      selected_spoc: [],
      selected_course: "",
      init_selected_team: [],
      treeTeam: [
        {
          id: "All",
          label: "All",
          children: [
            { id: "CM", label: "CM" },
            { id: "SR", label: "SR" },
            // { id: "ExEd", label: "MAcc" },
          ]
        }
      ],
      cities: [
        "Ahmedabad",
        "Bangalore",
        "Chennai",
        "Delhi",
        "Ernakulam",
        "Hyderabad",
        "Kolkata",
        "Mumbai",
        "Pune",
      ],
      courses: ["CMA", "CPA", "CFA", "FRM"],
      date_range: {
        startDate: new Date(),
        endDate: new Date(),
      },
      gm_spocs: [],
      selected_gm_spoc: "",
      loan_status: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ],
      selected_loan_status_object: "",
      selected_loan_status: null,
      due_types: [
        { text: "Due", value: "due" },
        { text: "Over due", value: "over_due" },
        { text: "Incorrect fee", value: "incorrect_fee" },
        { text: "Defaulter", value: "defaulter" },
      ],
      selected_due_type_object: "",
      selected_due_type: "",
      due_summary_count: [],
      due_summary_sum: [],
      dues_raw: [],
      dues_raw_popup: false,
      popup_due_status: [],
      popup_title: "",
      gm_ids: [],
      user_with_gms: "",
      spoc_ids_data: "",
      initSelectedCity: [],
      treeDataCity: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      initSelectedGM: [],
      treeDataGM: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      initSelectedSpoc: [],
      treeDataSpoc: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      selected_teams: [],
      disabled: false,
      login_spoc_id:"",
      loggedin_team: "",
    };
  },
  mounted() {
    this.login_spoc_id = localStorage.getItem("spoc_id");
    this.userWiseTeam();
    this.getUsersWithGMs();
    this.getYears();
    let current_year = new Date().getFullYear();
    this.date_range.startDate = new Date(current_year + '-01-01');
    this.date_range.endDate = new Date(current_year + '-12-31');
    eventbus.$on("reload-dues-count-sum-data", () => {
      this.getData();
    });
    // this.getGMSpocs();
    // this.getData();
  },
  watch: {
    init_selected_team: function () {
      this.mergeAllSearch();
    },
    initSelectedCity: function (val) {
      if(val.length != 0){
        this.mergeAllSearch();
      }
      else{
        this.selected_city = [];
        this.getSpocIds()
      }
    },
    initSelectedGM: function (val) {
      if(val.length != 0){
        this.mergeAllSearch();
      }
      else{
        this.selected_gm = [];
        this.getSpocIds()
      }
    },
    initSelectedSpoc: function (val) {
      if(val.length != 0){
        this.mergeAllSearch();
      }
      else{
        this.selected_spoc = [];
        this.getSpocIds()
      }
    },
    selected_loan_status_object(val) {
      console.log("selected_loan_status_object",val);
      if(val != null){
        this.selected_loan_status = val.value;
      }
      if(val == null){
        this.selected_loan_status = null;
      }
      console.log("selected_loan_status",this.selected_loan_status);
    },
    selected_due_type_object(val){
      console.log("selected_due_type_object", val);
      if(val != null){
        this.selected_due_type = val.value;
      }
      if(val == null){
        this.selected_due_type = "";
      }
    },
    selected_year(val){
      if(val != ''){
        this.date_range.startDate = '';
        this.date_range.endDate = '';
      }
    },
    date_range(){
      this.selected_year = '';  
    }
  },
  methods: {
    userWiseTeam() {
            this.init_selected_team = [];
            let login_sub_team = localStorage.getItem('sub_team');
            if(login_sub_team == 'Collection' && localStorage.getItem('team') == 'SR'){
              this.loggedin_team = 'Admin'
            }else{
             this.loggedin_team = localStorage.getItem('team');
            }
            if(this.loggedin_team == 'Admin'){
                this.disabled = false;
            } else {
                this.disabled = true;
            }
            if (this.loggedin_team === 'Admin') {
                this.init_selected_team.push('CM');
            }
            if (this.loggedin_team === 'CM') {
                this.init_selected_team.push('CM');
            } else if (this.loggedin_team === 'SR') {
                this.init_selected_team.push('SR');
            } else if (this.loggedin_team === 'ExEd') {
                this.init_selected_team.push('ExEd');
            } else if (this.loggedin_team === 'GM' && localStorage.getItem("sub_team") == 'CM') {
                this.init_selected_team.push('CM');
            }
            else if (this.loggedin_team === 'GM' && localStorage.getItem("sub_team") != 'CM') {
                this.init_selected_team.push('SR');
            }
        },
    getUsersWithGMs() {
      let url = `${constants.SERVER_API}getUsersWithGMs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          let logged_in_user = localStorage.getItem('spoc_id')
          let team = this.loggedin_team
          if (team == 'CM' || team == 'SR' || team == 'ExEd') {
            response.data.forEach(ele => {
              if (logged_in_user == ele.id) {
                this.user_with_gms = []
                this.user_with_gms.push(ele);
                this.getDropdownData();
              }
            });
          } else if (localStorage.getItem('team') == 'GM') {
            this.getGmReportingSpocs(response.data)
          } else {
            this.user_with_gms = response.data
            this.getDropdownData();
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getGmReportingSpocs(res) {
      let gm_id = localStorage.getItem("spoc_id");
      let ids = [];
      let url = `${constants.SERVER_API}getSpocsOfGM?gm_id=${gm_id}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          for (let i = 0; i < response.data.spocs.length; i++) {
            const element = response.data.spocs[i];
            ids.push(element.id);
          }
          this.gm_ids = ids
          this.user_with_gms = []
          this.gm_ids.forEach(gm => {
            res.forEach(ele => {
              if (ele.id == gm) {
                this.user_with_gms.push(ele);
              }
            });
          });
          this.getDropdownData();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getDropdownData() {
      this.initSelectedCity = [];
      this.initSelectedGM = [];
      this.initSelectedSpoc = [];
      let uniqe_city = [...new Set(this.user_with_gms.map((item) => item.city))].sort();
      let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
      uniqe_city.forEach(element => {
        let obj = {
          id: element,
          label: element,
          children: []
        }
        if(city_options.includes(element)){
          this.treeDataCity[0].children.push(obj);
        }
      })
      this.user_with_gms.forEach((element) => {
        this.treeDataCity[0].children.forEach((city) => {
          if (element.city === city.label) {
            let cc_set = new Set(city.children.map((item) => item.label));
            if (!cc_set.has(element.city_classification) && element.city_classification != "None") {
            if(this.login_spoc_id == 132 || this.login_spoc_id == 937 || this.login_spoc_id == 39 || this.login_spoc_id == 2613 || this.login_spoc_id == 2474){
              let cc_obj = {
                id: element.city + "_" + element.city_classification,
                label: element.city_classification
              }
              city.children.push(cc_obj);
              let team = this.loggedin_team
              if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                this.initSelectedCity.push(cc_obj)
              }
              this.default_selected_city = this.initSelectedCity;
              }else{
                if(element.city_classification != "X"){
                  let cc_obj = {
                  id: element.city + "_" + element.city_classification,
                  label: element.city_classification
                }
                city.children.push(cc_obj);
                let team = this.loggedin_team
                if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                  this.initSelectedCity.push(cc_obj)
                }
                this.default_selected_city = this.initSelectedCity;
                }
                
              }
              
            }
            // }
          }
        })
      });
      uniqe_city.forEach(element => {
        let obj = {
          id: element,
          label: element,
          children: []
        }
        this.treeDataGM[0].children.push(obj);
      })
      this.user_with_gms.forEach((element) => {
        this.treeDataGM[0].children.forEach((city) => {
          if (element.city === city.label) {
            if (element.gm !== "No GM") {
              let gm_set = new Set(city.children.map((item) => item.label));
              if (!gm_set.has(element.gm)) {
                if(this.login_spoc_id == 132 || this.login_spoc_id == 937 || this.login_spoc_id == 39 || this.login_spoc_id == 2613 || this.login_spoc_id == 2474){
                  let gm_obj = {
                    id: element.city + "_" + element.gm,
                    label: element.gm
                  }
                  city.children.push(gm_obj);
                  let team = this.loggedin_team
                  if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                    this.initSelectedGM.push(gm_obj)
                  }
                  this.default_selected_gm = this.initSelectedGM
                }else{
                  if(element.gm.substring(0,1) != "X"){
                      let gm_obj = {
                      id: element.city + "_" + element.gm,
                      label: element.gm
                    }
                    city.children.push(gm_obj);
                    let team = this.loggedin_team
                    if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                      this.initSelectedGM.push(gm_obj)
                    }
                    this.default_selected_gm = this.initSelectedGM
                  }
                }
              }
            }
          }
        })
      });

      uniqe_city.forEach(element => {
        let obj = {
          id: element,
          label: element,
          children: []
        }
        this.treeDataSpoc[0].children.push(obj);
      })
      this.user_with_gms.forEach((element) => {
        this.treeDataSpoc[0].children.forEach((city) => {
          if (element.city === city.label) {
            let gm_set = new Set(city.children.map((item) => item.label));
            if (!gm_set.has(element.last_name)) {
            if(this.login_spoc_id == 132 || this.login_spoc_id == 937 || this.login_spoc_id == 39 || this.login_spoc_id == 2613 || this.login_spoc_id == 2474){
              let spoc_obj = {
                id: element.city + "_" + element.first_name + " " + element.last_name,
                label: element.first_name + " " + element.last_name
              }
              city.children.push(spoc_obj);
              let team = this.loggedin_team
              if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                this.initSelectedSpoc.push(spoc_obj)
              }
              this.default_selected_spoc = this.initSelectedSpoc
            }else{
              if(element.first_name.substring(0,1) != "X"){
                let spoc_obj = {
                id: element.city + "_" + element.first_name + " " + element.last_name,
                label: element.first_name + " " + element.last_name
              }
              city.children.push(spoc_obj);
              let team = this.loggedin_team
              if (team == 'CM' || team == 'SR' || team == 'GM' || team == 'ExEd') {
                this.initSelectedSpoc.push(spoc_obj)
              }
              this.default_selected_spoc = this.initSelectedSpoc
              }
            }
            }
          }
        })
      });
      this.getData();
    },
    getSpocIds() {
      let city = [];
      let cc = [];
      let uniqe_city = [];
      let uniqe_gm = [];
      let uniqe_spoc = [];
      if (this.initSelectedCity.length != 0) {
        this.initSelectedCity.forEach(item => {
          if (item.hasOwnProperty('children')) {
            city.push(item.id);
            item.children.forEach(CC => {
              cc.push(CC.label)
            })
          }
          else if (!item.hasOwnProperty('children')) {
            city.push(item.id);
            cc.push(item.label)
          }
        });

        uniqe_city = city.map((element) => element.replace(/_.+$/, ''));
        uniqe_city = [...new Set(uniqe_city.map((item) => item))];
      }
      if (this.initSelectedGM.length != 0) {
        this.initSelectedGM.forEach(item => {
          if (item.hasOwnProperty('children')) {
            city.push(item.id);
            item.children.forEach(data => {
              uniqe_gm.push(data.label)
            })
          }
          else if (!item.hasOwnProperty('children')) {
            city.push(item.id);
            uniqe_gm.push(item.label)
          }
        });
        uniqe_city = city.map((element) => element.replace(/_.+$/, ''));
        uniqe_city = [...new Set(uniqe_city.map((item) => item))];
      }
      if (this.initSelectedSpoc.length != 0) {
       
        this.initSelectedSpoc.forEach(item => {
          if (item.hasOwnProperty('children')) {
            city.push(item.id);
            item.children.forEach(data => {
              uniqe_spoc.push(data.label)
            })
          }
          else if (!item.hasOwnProperty('children')) {
            city.push(item.id);
            uniqe_spoc.push(item.label)
          }
        });
        uniqe_city = city.map((element) => element.replace(/_.+$/, ''));
        uniqe_city = [...new Set(uniqe_city.map((item) => item))];
      }
      let spoc_ids = [];
      this.user_with_gms.forEach(raw => {
        if(this.init_selected_team.length != 0 && this.initSelectedCity.length == 0 && this.initSelectedGM.length == 0 && this.initSelectedSpoc.length == 0){
          this.selected_teams.forEach(team => {
            if(raw.team == team){
              spoc_ids.push(raw.id);
            }
          })
        }
        else if(this.init_selected_team.length == 0 && this.initSelectedCity.length != 0 && this.initSelectedGM.length == 0 && this.initSelectedSpoc.length == 0){
          uniqe_city.forEach(city => {
            cc.forEach(cc => {
              if (raw.city == city && raw.city_classification == cc) {
                spoc_ids.push(raw.id);
              }
            })
          })
        }
        else if(this.init_selected_team.length == 0 && this.initSelectedCity.length == 0 && this.initSelectedGM.length != 0 && this.initSelectedSpoc.length == 0){
          uniqe_gm.forEach(gm => {
            if(raw.gm == gm){
              spoc_ids.push(raw.id);
            }
          })
        }
        else if(this.init_selected_team.length == 0 && this.initSelectedCity.length == 0 && this.initSelectedGM.length == 0 && this.initSelectedSpoc.length != 0){
          uniqe_spoc.forEach(spoc => {
            if(raw.first_name + " " + raw.last_name == spoc){
              spoc_ids.push(raw.id);
            }
          })
        }
        else if(this.init_selected_team.length != 0 && this.initSelectedCity.length != 0 && this.initSelectedGM.length == 0 && this.initSelectedSpoc.length == 0){
          this.selected_teams.forEach(team => {
            uniqe_city.forEach(city => {
              cc.forEach(cc => {
              if(raw.team == team && raw.city == city && raw.city_classification == cc){
                spoc_ids.push(raw.id);
              }
            })
          })
          })
        }
        else if(this.init_selected_team.length == 0 && this.initSelectedCity.length != 0 && this.initSelectedGM.length != 0 && this.initSelectedSpoc.length == 0){
            uniqe_city.forEach(city => {
              cc.forEach(cc => {
              uniqe_gm.forEach(gm => {
              if(raw.city == city && raw.city_classification == cc && raw.gm == gm){
                spoc_ids.push(raw.id);
              }
            })
          })
          })
        }
        else if(this.init_selected_team.length == 0 && this.initSelectedCity.length != 0 && this.initSelectedGM.length == 0 && this.initSelectedSpoc.length != 0){
            uniqe_city.forEach(city => {
              cc.forEach(cc => {
                uniqe_spoc.forEach(spoc => {
              if(raw.city == city && raw.city_classification == cc && raw.first_name + " " + raw.last_name == spoc){
                spoc_ids.push(raw.id);
              }
            })
          })
          })
        }
        else if(this.init_selected_team.length == 0 && this.initSelectedCity.length == 0 && this.initSelectedGM.length != 0 && this.initSelectedSpoc.length != 0){
          uniqe_gm.forEach(gm => {
            uniqe_spoc.forEach(spoc => {
              if(raw.gm == gm && raw.first_name + " " + raw.last_name == spoc){
                spoc_ids.push(raw.id);
              }
            })
          })
        }
        else if(this.init_selected_team.length != 0 && this.initSelectedCity.length == 0 && this.initSelectedGM.length == 0 && this.initSelectedSpoc.length != 0){
          this.selected_teams.forEach(team => {
            uniqe_spoc.forEach(spoc => {
              if(raw.team == team && raw.first_name + " " + raw.last_name == spoc){
                spoc_ids.push(raw.id);
              }
            })
          })
        }
        else if(this.init_selected_team.length != 0 && this.initSelectedCity.length == 0 && this.initSelectedGM.length != 0 && this.initSelectedSpoc.length == 0){
          this.selected_teams.forEach(team => {
            uniqe_gm.forEach(gm => {
              if(raw.team == team && raw.gm == gm){
                spoc_ids.push(raw.id);
              }
            })
          })
        }
        else if(this.init_selected_team.length != 0 && this.initSelectedCity.length != 0 && this.initSelectedGM.length != 0 && this.initSelectedSpoc.length == 0){
          this.selected_teams.forEach(team => {
            uniqe_city.forEach(city => {
              cc.forEach(cc => {
              uniqe_gm.forEach(gm => {
                if(raw.team == team && raw.city == city && raw.city_classification == cc && raw.gm == gm){
                  spoc_ids.push(raw.id);
                }
              })
            })
            })
          })
        }
        else if(this.init_selected_team.length == 0 && this.initSelectedCity.length != 0 && this.initSelectedGM.length != 0 && this.initSelectedSpoc.length != 0){
          uniqe_city.forEach(city => {
            cc.forEach(cc => {
              uniqe_gm.forEach(gm => {
                uniqe_spoc.forEach(spoc => {
                if(raw.city == city && raw.city_classification == cc && raw.gm == gm && raw.first_name + " " + raw.last_name == spoc){
                  spoc_ids.push(raw.id);
                }
              })
            })
          })
          })
        }
        else if(this.init_selected_team.length != 0 && this.initSelectedCity.length == 0 && this.initSelectedGM.length != 0 && this.initSelectedSpoc.length != 0){
          this.selected_teams.forEach(team => {
              uniqe_gm.forEach(gm => {
                uniqe_spoc.forEach(spoc => {
                if(raw.team == team && raw.gm == gm && raw.first_name + " " + raw.last_name == spoc){
                  spoc_ids.push(raw.id);
                }
              })
            })
          })
        }
        else if(this.init_selected_team.length != 0 && this.initSelectedCity.length != 0 && this.initSelectedGM.length == 0 && this.initSelectedSpoc.length != 0){
          this.selected_teams.forEach(team => {
            uniqe_city.forEach(city => {
              cc.forEach(cc => {
                uniqe_spoc.forEach(spoc => {
                if(raw.team == team && raw.city == city && raw.city_classification == cc && raw.first_name + " " + raw.last_name == spoc){
                  spoc_ids.push(raw.id);
                }
              })
            })
            })
          })
        }
        else if(this.init_selected_team.length != 0 && this.initSelectedCity.length != 0 && this.initSelectedGM.length != 0 && this.initSelectedSpoc.length != 0){
          this.init_selected_team.forEach(team => {
            uniqe_city.forEach(city => {
              cc.forEach(cc => {
              uniqe_gm.forEach(gm => {
                uniqe_spoc.forEach(spoc => {
                if(raw.team == team && raw.city == city && raw.city_classification == cc && raw.gm == gm && raw.first_name + " " + raw.last_name == spoc){
                  spoc_ids.push(raw.id);
                }
              })
            })
            })
            })
          })
        } 
      });
      spoc_ids = [...new Set(spoc_ids.map((data) => data))];
      this.spoc_ids_data = spoc_ids.join();
      // this.open_raw_data_popup = false;
      // this.maccCmLevelSummary();
      console.log("spoc ids", this.spoc_ids_data);
    },
    mergeAllSearch() {
      this.selected_teams = [];
      this.init_selected_team.forEach((sort) => {
        if (sort === "All") {
          this.treeTeam[0].children.forEach((child) => {
              this.selected_teams.push(child.label);
          });
        } else {
            this.selected_teams.push(sort);
          }
      });
      this.selected_city = [];
      this.initSelectedCity.forEach((sort) => {
        if (sort.label === "All") {
          this.treeDataCity[0].children.forEach((child) => {
            this.selected_city.push(child.label);
          });
        } else {
          this.selected_city.push(sort.label);
        }
      });
      this.selected_gm = [];
      this.initSelectedGM.forEach((sort) => {
        if (sort.label === "All") {
          this.treeDataGM[0].children.forEach((child) => {
            this.selected_gm.push(child.label);
          });
        } else {
          this.selected_gm.push(sort.label);
        }
      });
      this.selected_spoc = [];
      this.initSelectedSpoc.forEach((sort) => {
        if (sort.label === "All") {
          this.treeDataSpoc[0].children.forEach((child) => {
            this.selected_spoc.push(child.label);
          });
        } else {
          this.selected_spoc.push(sort.label);
        }
      });
      // this.getSpocIds()
    },
    // refresh(){
    //   this.selected_city = "";
    //   this.selected_course = "";
    //   this.selected_gm_spoc = "";
    //   this.selected_loan_status = null;
    //   this.selected_due_type_object = "";
    //   this.selected_year = "";
    //   let current_year = new Date().getFullYear();
    //   this.date_range.startDate = new Date(current_year + '-01-01');
    //   this.date_range.endDate = new Date(current_year + '-12-31');

    // },
    getYears() {
      let current_year = new Date().getFullYear();
      console.log("current year", current_year);
      for (let i = current_year; i >= 2011; i--) {
        this.years_list.push(i);
      }
      console.log(this.years_list, "years list");
    },
    customFormatter(enrollment_date) {
      return moment(enrollment_date).format("DD-MMM-YYYY");
    },
    numberWithCommas(x) {
      return x.toLocaleString('en-IN');
    },
    displayPopupData(due_status, dues_count, selected_year, course, title){
      console.log(due_status, course);
      this.popup_due_status = [];
      for (let i = 0; i < this.dues_raw.length; i++) {
        const element = this.dues_raw[i];
        const d = new Date(element.enrollment_date);
        let date = d.getFullYear();
        
        if(due_status != 'total_pending'){
          if(element.status == due_status && element.course == course && selected_year == date){
            this.popup_due_status.push(element);
          } 
        }
        if(due_status == 'total_pending' && element.course == course && selected_year == date){
          this.popup_due_status.push(element);
        } 
      }
      console.log("popup due status",this.popup_due_status);
      this.popup_title = course+" "+"-"+" "+title+" "+"-"+" "+this.numberWithCommas(dues_count)+" "+"-"+" "+selected_year;
      eventbus.$emit("duesummary-sumrawcount-popup", this.popup_due_status, this.popup_title, due_status);
      // this.dues_raw_popup = true;
    },
    getGMSpocs() {
      let url = `${constants.SERVER_API}getGMSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getGMSpocs", response);
          this.gm_spocs = [];
          for (let i = 0; i < response.data.spocs.length; i++) {
            const element = response.data.spocs[i];
            this.gm_spocs.push(element.full_name);
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getData() {
      this.getSpocIds();
      this.$vs.loading();
      let start_date = '';
      let end_date = '';
      start_date = moment(this.date_range.startDate).format("YYYY-MM-DD");
      end_date = moment(this.date_range.endDate).format("YYYY-MM-DD");
      if(this.selected_year != null && this.selected_year != ''){
        let selected_year_start_date = new Date(this.selected_year + '-01-01');
        let selected_year_end_date = new Date(this.selected_year + '-12-31');
        start_date = moment(selected_year_start_date).format("YYYY-MM-DD");
        end_date = moment(selected_year_end_date).format("YYYY-MM-DD");
      }
      console.log(
        this.selected_city,
        this.selected_course,
        this.selected_gm_spoc,
        this.selected_loan_status,
        start_date,
        end_date,
        this.selected_due_type
      );
      let filter_object = {
        end_date: end_date,
        start_date: start_date,
        user_id: this.spoc_ids_data
      }
      // if(this.selected_city != ''){
      //   filter_object.city = this.selected_city;
      // }
      if(this.selected_course != ''){
        filter_object.course = this.selected_course;
      }
      // if(this.selected_gm_spoc != ''){
      //   filter_object.gm = this.selected_gm_spoc;
      // }
      if(this.selected_loan_status != null){
        filter_object.loan = this.selected_loan_status;
      }
      if(this.selected_due_type != ''){
        filter_object.status = this.selected_due_type;
      }
      this.getDuesSummarySum(filter_object);
      this.getDuesRaw(filter_object);
      
    },
    getDuesSummarySum(filter_object){
      this.due_summary_sum = [];
      let url = `${constants.MILES_FINANCE}duesSummarySum`;
      axios
        .get(url, {params :filter_object})
        .then(response => {
          this.$vs.loading.close();
          console.log("dues-summary-sum", response);
          this.due_summary_sum = response.data;
          this.getDuesSummaryCount(filter_object);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    getDuesSummaryCount(filter_object){
      this.due_summary_count = [];
      let url = `${constants.MILES_FINANCE}duesSummaryCount`;
      axios
        .get(url, {params :filter_object})
        .then(response => {
          console.log("dues-summary-count", response);
          this.due_summary_count = response.data;
          this.due_summary_count.forEach(count_element => {
            for (let i = 0; i < this.due_summary_sum.length; i++) {
              const sum_element = this.due_summary_sum[i];
              if(sum_element.course == count_element.course && sum_element.year == count_element.year && sum_element.status == count_element.status){
                count_element.sum_object = sum_element;
              }
            }
          });
          console.log("after merge sum",this.due_summary_count);
          this.$vs.loading.close();
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    getDuesRaw(filter_object){
      this.dues_raw = [];
      let url = `${constants.MILES_FINANCE}duesRaw`;
      axios
        .get(url, {params :filter_object})
        .then(response => {
          this.$vs.loading.close();
          console.log("dues-summary-sum", response);
          this.dues_raw = response.data;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
  },
};
</script>

<style>
.popupwidth .vs-popup {
  min-width: 98%;
}
.vue-treeselect__control {
  border: 2px solid #c2bfbf;
}

</style>
